import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../../assets/scss/nav.module.scss';

class Nav extends React.Component {

    constructor(props) {
        super(props)
        const { pathname } = window.location
        const active = pathname.includes('/blog')
            ? 'blog'
            : pathname === '/'
                ? 'about'
                : pathname.replace('/', '')
        this.state = {
            active
        }
    }

    render() {
        const { active } = this.state
        return (
            <div className={styles.nav}>
                <Link to="/" onClick={() => this.setState({ active: 'about' })} className={active === 'about' ? styles.active : ''}>
                    <div className={styles.logo}>
                        <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Logo-Scholarship2020.png" alt="" />
                    </div>
                </Link>
                <div className={styles.links}>
                    <Link to="/" onClick={() => this.setState({ active: 'about' })} className={active === 'about' ? styles.active : ''}>About</Link>
                    <Link to="/blog" onClick={() => this.setState({ active: 'blog' })} className={active === 'blog' ? styles.active : ''}>Blog</Link>
                    <Link to="/contact" onClick={() => this.setState({ active: 'contact' })} className={active === 'contact' ? styles.active : ''}>Contact Us</Link>
                </div>
            </div>
        );
    }
}

export default Nav