import React from 'react';
import styles from '../../../assets/scss/carousel.module.scss'

class Carousel extends React.Component {

    state = {
        current: 0,
    }

    handlePrev() {
        if (this.state.current - 1 >= 0)
            this.setState(prevState => ({ current: prevState.current - 1 }))
        else
            this.setState({ current: this.props.children.length - 1 });
    }

    handleNext() {
        if (this.state.current + 1 < this.props.children.length)
            this.setState(prevState => ({ current: prevState.current + 1 }))
        else
            this.setState({ current: 0 })
    }

    render() {
        const { children } = this.props
        return <div className={styles.carousel}>
            <div
                className={styles.prev}
                onClick={() => this.handlePrev()}>

                <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_left.png" alt="" />
            </div>
            <div style={{ position: "relative", width: '100%' }}>
                {
                    // check wether there are multiples child
                    children
                    && (children.length
                        ? children.map((child, i) => {
                            return <div
                                key={i}
                                className={`${styles.item} ${this.state.current === i ? styles.show : styles.hidden}`}>
                                {child}
                            </div>
                        }

                        ) : children)

                }

            </div>
            <div
                className={styles.next}
                onClick={() => this.handleNext()}>

                <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Right.png" alt="" />
            </div>
        </div>
    }
}

export default Carousel