const main_state = {
	login : false,
	loader: false,
	lang_json: false,
	popup_open: false,
	popup_type: false,
	open_alert: false,
	open_ads: false,
	open_error: true,
	alert_msg: '',
	alert_title: '',

	// FORM
	name: '',
	mail: '',
	phone: '',
	messages: ''
}

const reset_state = {
	name: '',
	mail: '',
	phone: '',
	messages: ''
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "RESET_FORM":
			return { ...state, ...reset_state}
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		case "TOGGLE_POPUP":
			return { ...state, popup_open: action.bool, popup_type: action.tipe}
		default:
			return state;
	}
};

export default main;
