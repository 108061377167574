import React from 'react';
import { useParams, Link } from "react-router-dom";
import styles from '../../assets/scss/blog.module.scss'
import moment from 'moment'

function BlogDetail(props) {
    let { id } = useParams()

    const { news_detail } = props.main

    let date = undefined
    if (!news_detail || `${news_detail.data.id}` !== id) {
        props.actionsMain.get_news_detail(id)
    } else {
        date = moment(news_detail.data.date).format('D MMMM YYYY')

    }

    return <div className={styles.blog_detail}>
        <div className={styles.back}><Link to="/blog">{"< back"}</Link></div>
        {
            news_detail &&
            <div>
                <h1>{news_detail.data.title}</h1>
                <div className={styles.date}>{date}</div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: news_detail.data.content }}></div>
            </div>
        }

    </div>
}

export default BlogDetail;