import axios from 'axios';
import { getUrlParam } from 'helper';

const ls = window.localStorage;


const cdn = axios.create({
    baseURL: `https://cdngarenanow-a.akamaihd.net/webid/Localize/${process.env.REACT_APP_LOCALIZE_NAME}/`,
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
})


const directus = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    withCredentials: false,
    headers: { 
        Authorization: `Bearer VWSjJrbRXR`,
    },
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
})


export const toggle_popup = (bool, tipe) => ({
    type: 'TOGGLE_POPUP',
    bool,
    tipe
})


export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data: data
})


export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

export const reset_form = () => ({
    type: 'RESET_FORM'
})


/**
 * 
 * Example of how to call API
 * 
 */
export const login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))

        axios.get(process.env.REACT_APP_API_ENDPOINT)
            .then((resp) => {
                dispatch(put_data('login', resp.data))
            })
            .catch((err) => {
                dispatch(catch_error(err?.response?.data?.msg));
            })
            .then(() => {
                dispatch(toggle_loader(false))
            })
    }
}


/**
 * 
 * Example of how to implement multi language 
 * 
 */
export const get_translate = (lang) => {
    return dispatch => {
        cdn.get(`${lang}.json?${Math.random() * 10000}`)
            .then(resp => {
                dispatch(put_data('lang_json', resp.data))
            })
            .catch(err => {

            })
    }
}


/**
 * 
 * Example of how to catch error if not logged in
 * 
 */
export const catch_error = (err_msg) => {
    return (dispatch) => {
        if (err_msg === 'not_logged_in') {
            const param = {
                accessToken: getUrlParam("access_token") || "",
                accountId: getUrlParam("account_id") || "",
                region: getUrlParam("region") || ""
            };

            ls.removeItem('cache');
            ls.removeItem('hash');

            window.location.href = `${`${process.env.REACT_APP_BASE_URL}?access_token=${param.accessToken}&account_id=${param.accountId}&region=${param.region}`}`;
        } else {
            if (err_msg) {
                dispatch(put_data('err_msg', err_msg))
            }
            dispatch(toggle_popup(true, 'error'))
            dispatch(toggle_loader(false))
        }
    }
}

export const toggle_modal = () => {
    return (dispatch, getState) => {
        let {main} = getState()
        dispatch(put_data('open_alert', !main.open_alert))
    }
}

export const toggle_ads = () => {
    return (dispatch, getState) => {
        let {main} = getState()
        dispatch(put_data('open_ads', !main.open_ads))
    }
}

export const toggle_error = () => {
    return (dispatch, getState) => {
        let {main} = getState()
        dispatch(put_data('open_error', !main.open_error))
    }
}

export const get_ads = () => {
    return dispatch => {
        directus.get('scholarship_ads')
        .then(res => {
            dispatch(put_data('ads', res.data.data))
            if (window.location.pathname === '/' && res.data.data.enabled !== 'false' && res.data.data.image  && res.data.data.url) {
                dispatch(put_data('open_ads', true))
            }
        })
    }
}

export const get_news = () => {
    return dispatch => {
        directus.get('scholarship_news?sort=-date')
            .then(res =>
                dispatch(put_data('news', res.data)))
    }
}

export const get_news_detail = (id) => {
    return dispatch => {
        directus.get(`scholarship_news/${id}`)
            .then(res =>
                dispatch(put_data('news_detail', res.data)))
    }
}


export const get_about = () => {
    return dispatch => {
        directus.get(`scholarship_about`)
            .then(res =>
                dispatch(put_data('about', res.data))
            )
    }
}

export const get_testimony = () => {
    return dispatch => {
        directus.get(`scholarship_testimoni`)
            .then(res =>
                dispatch(put_data('testimonies', res.data)))
    }
}

export const get_timeline = () => {
    return dispatch => {
        directus.get(`scholarship_timeline?sort=sort`)
            .then(res =>
                dispatch(put_data('timeline', res.data)))
    }
}

export const post_mail = (data) => {
    return dispatch => {
        axios.post(process.env.REACT_APP_API_ENDPOINT + 'scholarship_mail', data, {headers: { 
            Authorization: `Bearer VWSjJrbRXR`,
        }})
        .then(res => {
            dispatch(put_data('alert_title', 'Thank you for your messages!'))
            dispatch(put_data('alert_msg', 'Messages successfully send'))
            dispatch(toggle_modal())
            dispatch(reset_form())
        })
        .catch(err => {
            dispatch(put_data('alert_title', 'Error!'))
            dispatch(put_data('alert_msg', 'Messages failed to send, please try again or contact our support team at https://help.garena.co.id/'))
            dispatch(toggle_modal())
        })
    }
}
