import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import ScrollToTop from 'helper/component/ScrollToTop';
import Layout from 'helper/component/layout/layout';
import AlertModal from 'partial/Modal';
import AdsModal from 'partial/Modal/Ads';
// import MaintenaceModal from 'partial/Modal/Maintenance';

// Pages
import Home from 'pages/Home';
import Blog from 'pages/Blog';
import BlogDetail from 'pages/Blog/detail';
import Contact from 'pages/Contact';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';


class Main extends Component {
	componentDidMount() {
		this.props.actionsMain.get_ads();
	}

	render() {
		return (
			<Router basename={process.env.PUBLIC_URL}>
				<ScrollToTop />
				<Layout>
					<AdsModal />
					<AlertModal />
					{/* <MaintenaceModal /> */}

					<div id="Main" className="main-panel">
						<ErrorBoundary>
							<ViewportRestrict display={false} type="landscape" />
							<Loader loader={false} />
							<div className="content-container">
								<Switch>
									<Route exact path="/" component={() => <Home {...this.props} />} />
									<Route exact path="/blog" component={() => <Blog {...this.props} />} />
									<Route exact path="/blog/:id" component={() => <BlogDetail {...this.props} />} />
									<Route exact path="/contact" component={Contact} />
									<Route component={NotFound} />
								</Switch>
							</div>
						</ErrorBoundary>
					</div>
				</Layout>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
