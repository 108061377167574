import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class AdsModal extends Component {
    render() {
        const {ads, open_ads} = this.props.main;
        const {toggle_ads} = this.props.actionsMain;

        return (
            <Modal 
                classNames={{
                    modal: "modal-ads"
                }}
                focusTrapped={false}
                open={open_ads} 
                onClose={toggle_ads} 
                center
            >
                <div className="ads-modal-content">
                    <a href={ads?.url} target="_blank" rel="noopener noreferrer">
                        <img src={ads?.image} alt=""/>
                    </a>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return { 
        main: state.main
    }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsModal);
