import Carousel from 'helper/component/carousel';
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styles from '../../assets/scss/home.module.scss';

const convertNewlineToBr = (str) => {
    return str.split('\n').join('<br/>')
}
class Home extends Component {

    state = {
        show_nav: false,
        nav: ''
    }

    componentDidMount() {
        if (!this.props.main.about || !this.props.main.testimonies) {
            this.props.actionsMain.get_about()
            this.props.actionsMain.get_testimony()
            this.props.actionsMain.get_timeline()
        }

        window.addEventListener('scroll', this.handleRenderSideNav)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleRenderSideNav)
    }

    handleRenderSideNav = () => {
        let elementTarget = document.getElementById("landing");
        if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            this.setState({
                show_nav: true
            })
        }

        if (window.scrollY < (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            this.setState({
                show_nav: false
            })
        }
    }

    renderTimeline(tl) {
        const { data } = tl
        let result = []
        for (let i = 0; i < data.length; i++) {
            result.push(
                <div key={i} className={styles.item}>
                    <img src={`http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Timeline-${i + 1}.png`} alt="" />
                    <div className={styles.content}>
                        <div><strong>{data[i].text}</strong></div>
                        <div><span>{data[i].date}</span></div>
                    </div>
                </div>
            )

            if (i !== data.length - 1) {
                if (i === data.length - 2) {
                    result.push(
                        <div key={"arrow_" + i} className={styles.dash}>
                            <img src={`http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Timeline-Arrow.png`} alt="" />
                        </div>
                    )
                } else {
                    result.push(
                        <div key={"dash_" + i} className={styles.dash} >
                            <img src={`http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Timeline-Dash.png`} alt="" />
                        </div>
                    )
                }
            }
        }

        return <div className={styles.timeline}>
            {result}
        </div>
    }

    renderApplyButton = () => {
        const { about } = this.props.main;
        if (about?.data?.apply_open === true) {
            return (
                <a href={about?.data?.link_apply}>
                    <div className={styles.apply_button}></div>
                </a>
            )
        }
        else if (about?.data?.apply_open === false) {
            return (
                <div className={styles.closed}>
                    <span style={{ color: '#ff5c5c' }}>●</span> Applications for Sea Scholarship Indonesia 2021 are now closed
                </div>
            )
        }
    }

    render() {
        const { about, testimonies, timeline } = this.props.main

        return (

            <div className={styles.home}>
                <VisibilitySensor intervalCheck={true} partialVisibility onChange={(visible) => visible}>
                    <div id="landing" className={styles.banner}>
                        <div className={styles.content}>
                            <div className={styles.logo}>
                                <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/SeaScholarship-Title.png" alt="" />
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: about && convertNewlineToBr(about.data.landing_text) }}>
                            </p>
                            <div className={styles.apply}>
                                {this.renderApplyButton()}
                            </div>
                        </div>
                        <div className={styles.illust}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/BG_People.png" alt="" />
                        </div>
                    </div>
                </VisibilitySensor>

                {
                    this.state.show_nav
                    && <div className={styles.navigation}>
                        <a href="#about" onClick={() => this.setstate({ nav: 'about' })}>
                            <div>
                                About
                            </div>
                            <div className={styles.icon}>
                                {
                                    this.state.nav === 'about'
                                        ? <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Active.png" alt="" />
                                        : <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating.png" alt="" />
                                }
                            </div>
                        </a>
                        <div className={styles.bar}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Bar.jpg" alt="" />
                        </div>
                        <a href="#program" onclick={() => this.setstate({ nav: 'program' })}>
                            <div>
                                Program
                            </div>
                            <div className={styles.icon}>
                                {
                                    this.state.nav === 'program'
                                        ? <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Active.png" alt="" />
                                        : <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating.png" alt="" />
                                }
                            </div>
                        </a>
                        <div className={styles.bar}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Bar.jpg" alt="" />
                        </div>
                        <a href="#testimony" onclick={() => this.setstate({ nav: 'testimony' })}>
                            <div>
                                Meet Our Scholar
                            </div>
                            <div className={styles.icon}>
                                {
                                    this.state.nav === 'testimony'
                                        ? <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Active.png" alt="" />
                                        : <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating.png" alt="" />
                                }
                            </div>
                        </a>
                        <div className={styles.bar}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Bar.jpg" alt="" />
                        </div>
                        <a href="#faq" onclick={() => this.setstate({ nav: 'faq' })}>
                            <div>
                                FAQ
                            </div>
                            <div className={styles.icon}>
                                {
                                    this.state.nav === 'faq'
                                        ? <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating-Active.png" alt="" />
                                        : <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Floating.png" alt="" />
                                }
                            </div>
                        </a>
                    </div>
                }

                <div id="about" className={styles.about}>
                    <h2>About Sea</h2>
                    <VisibilitySensor intervalCheck={true} minTopValue="100" partialVisibility onChange={(visible) => visible && this.setState({ nav: 'about' })}>
                        {
                            about &&
                            <p dangerouslySetInnerHTML={{ __html: about.data.about_text.split('\n').join('<br/>') }}>
                            </p>
                        }
                    </VisibilitySensor>
                    <div className={styles.business}>
                        <h3>Our Business Units</h3>
                        <div className={styles.business_logos}>
                            <div className={styles.logo}>
                                <div className={styles.image_cont}>
                                    <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Logo_Garena.png" alt="" />
                                </div>
                                <div>Digital Entertainment</div>
                            </div>
                            <div className={styles.logo}>
                                <div className={styles.image_cont}>
                                    <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Logo_Shopee.png" alt="" />
                                </div>
                                <div>E-Commerce</div>
                            </div>
                            <div className={styles.logo}>
                                <div className={styles.image_cont}>
                                    <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Log_SeaMoney.png" alt="" />
                                </div>
                                <div>Digital Financial Services</div>
                            </div>
                        </div>
                    </div>
                </div>

                <VisibilitySensor intervalCheck={true} partialVisibility onChange={(v) => v && this.setState({ nav: 'program' })}>
                    <>
                        <div id="program" className={styles.program}>
                            <h2>Program</h2>
                            <p dangerouslySetInnerHTML={{ __html: about && convertNewlineToBr(about.data.program_text) }}></p>
                            <div className={styles.separator}>
                                <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Program.png" alt="" />
                            </div>
                            <p className={styles.list} dangerouslySetInnerHTML={{ __html: about && about.data.requirement_text }}></p>
                            <p className={styles.list} dangerouslySetInnerHTML={{ __html: about && about.data.benefit_text }}></p>
                            {timeline && this.renderTimeline(timeline)}
                        </div>
                        <div className={styles.cta}>
                            <h2>What are you waiting for?</h2>
                            <p>Register yourself to join in Sea Undergraduate Scholarship now!</p>
                            <a href={about && about.data.link_apply}>
                                <div className={styles.apply_button}></div>
                            </a>
                        </div>
                    </>
                </VisibilitySensor>

                <VisibilitySensor intervalCheck={true} onChange={(v) => v && this.setState({ nav: 'testimony' })}>
                    <div id="testimony" className={styles.testimonies}>
                        <h2> Meet our Scholars and Alumnae </h2>
                        <Carousel>
                            {testimonies && testimonies.data.map(
                                (testimony) => (<div key={testimony.id} className={styles.testimony_item}>
                                    <div className={styles.testimony_image}>
                                        <img src={testimony.image_url
                                            || "http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Meet-Our-Scholar_1.png"} alt="" />

                                    </div>
                                    <div className={styles.testimony_content}>
                                        <div className={styles.testimony_story} dangerouslySetInnerHTML={{ __html: convertNewlineToBr(testimony.testimoni) }}></div>
                                    </div>
                                </div>)
                            )}
                        </Carousel>
                    </div>
                </VisibilitySensor>

                <VisibilitySensor minTopValue="300" partialVisibility intervalCheck={true} onChange={(v) => v && this.setState({ nav: 'faq' })}>
                    <div id="faq" className={styles.faq}>
                        <h2>FAQ</h2>
                        {
                            about &&
                            <div dangerouslySetInnerHTML={{ __html: about.data.faq_text }}>
                            </div>
                        }
                    </div>
                </VisibilitySensor>
            </div >
        );
    }
}

export default Home;