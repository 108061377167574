import React, { Component } from 'react';
import validator from 'validator';
import styles from '../../assets/scss/contact.module.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Contact extends Component {
    handleChange = (type, e) => {
        const {put_data} = this.props.actionsMain;
        put_data(type, e.currentTarget.value)
    }

    toggle_alert = (msg) => {
        const {put_data, toggle_modal} = this.props.actionsMain;
        put_data('alert_title', 'Error!')
        put_data('alert_msg', msg);
        toggle_modal();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const {main} = this.props;

        // Validate Name
        let name = main.name.split(' ').join('');
        let is_valid_name = validator.isAlpha(name);
        if (!is_valid_name) {
            this.toggle_alert('Name not valid. Name can only contain alphabet and space only')
            return
        }

        // Validate Email
        let is_valid_email = validator.isEmail(main.mail);
        if (!is_valid_email) {
            this.toggle_alert('Email not valid. Email needs to be in correct format')
            return
        }

        // Validate Phone
        let is_valid_phone = validator.isNumeric(main.phone);
        if (!is_valid_phone) {
            this.toggle_alert('Mobile Number not valid. Mobile number should only contain numeric value')
            return
        }

        let data = {
            name: main.name.trim(),
            email: validator.normalizeEmail(main.mail.trim()),
            phone: main.phone,
            messages: validator.escape(main.messages)
        }

        this.props.actionsMain.post_mail(data);
    }

    render() {
        const {main} = this.props;
        
        return (
            <div className={styles.contact}>
                <h2>Get In Touch</h2>
                <form className={styles.form} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className={styles.label}>Your Name</div>
                    <div className={styles.input}>
                        <input type="text" onChange={(e) => this.handleChange('name', e)} value={main.name} required/>
                    </div>
                    <div className={styles.label}>Your Email</div>
                    <div className={styles.input}>
                        <input type="email" onChange={(e) => this.handleChange('mail', e)} value={main.mail} required/>
                    </div>
                    <div className={styles.label}>Your Mobile Number</div>
                    <div className={styles.input}>
                        <input type="text" onChange={(e) => this.handleChange('phone', e)} value={main.phone} required/>
                    </div>
                    <div className={styles.label}>Messages</div>
                    <div className={styles.input}>
                        <textarea onChange={(e) => this.handleChange('messages', e)} value={main.messages} required/>
                    </div>

                    <div className={styles.buttons}>
                        <div className={styles.button} onClick={() => this.props.actionsMain.reset_form()}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Clear.png" alt="" />
                        </div>
                        <button type="submit" className={styles.button}>
                            <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Button_Submit.png" alt="" />
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);