import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class AlertModal extends Component {
    render() {
        const {open_alert, alert_title, alert_msg} = this.props.main;
        const {toggle_modal} = this.props.actionsMain;

        return (
            <Modal open={open_alert} onClose={toggle_modal} center>
                <div className="alert-modal-content">
                    <h2>{alert_title}</h2>
                    <p>{alert_msg}</p>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);