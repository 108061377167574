import Nav from '../nav';
import Footer from '../footer';
import React, { Component } from 'react';
// import styles from './Home.module.css'

class Layout extends Component {
    render() {
        return (
            <div>
                <Nav />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}

export default Layout;