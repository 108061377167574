import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import styles from '../../assets/scss/blog.module.scss'

class Blog extends Component {
    componentDidMount() {
        if (!this.props.main.news)
            this.props.actionsMain.get_news();
    }

    render() {
        const { news } = this.props.main
        return (
            <div className={styles.blog}>
                <div className={styles.header}>
                    <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/BG-tab.jpg" alt="" />
                </div>
                <div className={styles.list}>
                    <h2>Latest News</h2>
                    {
                        news &&
                        news.data.map(n =>
                            <Link to={`/blog/${n.id}`}>
                                <div className={styles.posts} onClick={() => {
                                    this.props.actionsMain.get_news_detail(n.id)
                                }}>
                                    <div className={styles.post}>
                                        <div className={styles.thumbnail}>
                                            <img src={n.thumbnail_url || "http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Image_Newsletter.jpg"} alt="" />
                                        </div>
                                        <div className={styles.caption}>
                                            <h3>{n.title}</h3>
                                            <p>
                                                {n.short_description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                        )
                    }

                    {/* <div className={styles.view_more}>
                        View More
                    </div> */}
                </div>

            </div>
        );
    }
}

export default Blog;