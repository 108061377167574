import React from 'react'
import styles from '../../../assets/scss/footer.module.scss';

class Footer extends React.Component {

    render() {
        return (
            <div className={styles.footer}>
                <div className={styles.address}>
                    <div className={styles.logo}>
                        <img src="http://cdngarenanow-a.akamaihd.net/webid/schoolarship/Logo_Sea-Footer.png" alt="" />
                    </div>
                    <p className={styles.detail}>
                        Gama Tower L26 Jl. H. R. Rasuna Said Kav. C-22 <br />
                        Jakarta Selatan 12940 <br />
                        {/* Tel: +6221 80864211 */}
                        Email: <a href="mailto:id-scholarships@sea.com">id-scholarships@sea.com</a>
                    </p>
                    <div className={styles.ico}>
                        <a href='https://www.instagram.com/seascholarshipid/' target="_blank" rel='noreferrer noopener'>
                            <img alt='' src='http://cdngarenanow-a.akamaihd.net/webid/schoolarship/ico-ig.png' />
                        </a>
                    </div>
                </div>
                <div className={styles.more_info}>For more information about Sea, check out our corporate website <a href="https://www.seagroup.com/">here</a></div>
            </div>
        );
    }
}

export default Footer